import { defineStore } from 'pinia';

export const useGamesStore = defineStore('gamesStore', () => {
  const supportedCurrencies = ref(null);
  const gameFairnessData = ref(null);
  const gameServerUrl = ref(null);
  const assetUrl = ref(null);

  function $reset() {
    supportedCurrencies.value = null;
  }

  return {
    gameFairnessData,
    gameServerUrl,
    supportedCurrencies,
    assetUrl,
    $reset,
  };
});
